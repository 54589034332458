import React from 'react';
import { RECORDED_DATE_FORMAT, getAllFacts } from '@constants/facts';
import { CohortFilter, CohortJsonCondition } from 'models/cohorts';
import { UnitSystem } from './unitSystem';
import { DefinitionVariantType } from 'components/DefinitionList/DefinitionValue';
import { FactProps, FactState, FactValueStateProps } from 'models/facts';
import { ArrowUpIcon } from 'assets/icons';
import { formatInTimeZone } from 'date-fns-tz';
import { getGlobalTimezoneOffset } from './generatedData';

export const getFactByName = (
  condition: CohortJsonCondition,
  unitSystem: UnitSystem
): CohortFilter | undefined => {
  return getAllFacts(unitSystem).find((fact: CohortFilter) => {
    if (condition.fact === 'sex') {
      return fact.name === condition.fact && condition.one_of && fact.value === condition.one_of[0];
    }
    return fact.name === condition.fact;
  });
};

export const getVariantByFactState = ({ state }: FactProps): DefinitionVariantType | undefined => {
  if (!state) {
    return undefined;
  }

  switch (state) {
    case 'High':
    case 'Elevated':
    case 'Hypertension':
    case 'Hypertensive Crisis':
    case 'Obese':
    case 'Moderate/severe hyperglycemia':
    case 'Prehypertension':
      return 'error';
    default:
      return undefined;
  }
};

export const getIconByFactState = ({ state }: FactProps) => {
  if (!state) {
    return null;
  }

  switch (state) {
    case 'High':
    case 'Elevated':
    case 'Hypertension':
    case 'Hypertensive Crisis':
    case 'Obese':
    case 'Moderate/severe hyperglycemia':
    case 'Prehypertension':
      return <ArrowUpIcon />;
    default:
      return null;
  }
};

export const getFactValueState = ({
  state,
  lastValue,
  prevLastValue,
  lastRange,
  lastFact,
  prevLastRange,
  prevLastFact,
  recordedTimestamp,
  tz,
}: FactValueStateProps): FactState => {
  const recordedDate = recordedTimestamp
    ? formatInTimeZone(
        (recordedTimestamp - getGlobalTimezoneOffset(tz)) * 1000,
        tz,
        RECORDED_DATE_FORMAT
      )
    : '--';
  if (lastFact) {
    return {
      range: lastRange,
      value: lastValue,
      icon: getIconByFactState({
        state,
        lastFact: lastFact,
        prevLastFact: prevLastFact,
      }),
      variant: getVariantByFactState({ state, lastFact, prevLastFact }),
      recordedDate,
    };
  }

  if (prevLastFact) {
    return {
      range: prevLastRange,
      value: prevLastValue,
      icon: undefined,
      variant: 'stale' as DefinitionVariantType,
      recordedDate,
    };
  }

  return {
    value: '--',
    range: '--',
    icon: undefined,
    variant: 'stale' as DefinitionVariantType,
    recordedDate: '--',
  };
};
